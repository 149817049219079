<template>
	<article class="course-editor-content__chapters-container">
		<v-row>
			<v-col cols="8">
				<h3 v-if="!isEditing"><slot name="name"></slot></h3>
				<div v-else class="d-flex flex-row align-center">
					<InputTextField class="ma-0" v-model="newPageTitle"
						><template #label
							>New Page's title</template
						></InputTextField
					>
					<div class="ml-4">
						<v-icon @click="savePageTitle"
							>mdi-check-outline</v-icon
						>
					</div>
				</div>
			</v-col>
			<v-col cols="4">
				<div class="d-flex flex-row justify-end">
					<span>
						<v-icon v-if="!isEditing" @click="setIsEditing(true)"
							>mdi-pencil-outline</v-icon
						>
						<v-icon v-else @click="cancelEditting"
							>mdi-close</v-icon
						></span
					>
					<span
						><v-icon @click="$emit('removePage', activePageId)"
							>mdi-delete</v-icon
						></span
					>
				</div>
			</v-col>
		</v-row>
		<v-row>
			<v-col>

				<!-- <grid-layout :layout.sync="layout"
                     :responsive-layouts="layouts"
                     :col-num="12"
                     :row-height="30"
                     :is-draggable="draggable"
                     :is-resizable="resizable"
                     :vertical-compact="true"
                     :use-css-transforms="true"
                     :responsive="responsive"
                     @breakpoint-changed="breakpointChangedEvent"
        > -->

<section>


					<CourseEditorWidgetEditor
						v-for="blockEl in activePageBlocks"
						:key="blockEl.id"
						:block="blockEl"
						@editBlock="$emit('editBlock', $event)"
						@removeBlock="$emit('removeBlock', $event)"
						@changeBlkIndex="$emit('changeBlkIndex', $event)"
					>
						<template #content>
							<component
								:is="setComponent(blockEl)"
								:content="blockEl.block.content"
								:header="blockEl.block.header"
								:headerLevel="blockEl.block.head_level"
								:elements="blockEl.block.elements"
								:items="blockEl.block.items"
								:question="blockEl.block.question"
								:ordered="blockEl.block.ordered"
								:options="blockEl.block.options"
								:paragraphs="blockEl.block.paragraphs"
								:sourcesNodes="blockEl.block.source_nodes"
								:targetNodes="blockEl.block.target_nodes"
								:nodes="blockEl.block.nodes"
								:file="blockEl.block.file"
							></component>
						</template>
					</CourseEditorWidgetEditor>
</section>


			</v-col>
		</v-row>
	</article>
</template>
<script>
import { put } from "@/util/requests/put";
import CourseEditorWidgetEditor from "./CourseEditorWidgetEditor.vue";
import CourseEditorBlockParagraph from "./blocks/CourseEditorBlockParagraph.vue";
import CourseEditorBlockHeader from "./blocks/CourseEditorBlockHeader.vue";
import CourseEditorBlockList from "./blocks/CourseEditorBlockList.vue";
import CourseEditorBlockRadio from "./blocks/CourseEditorBlockRadio.vue";
import CourseEditorBlockCheckbox from "./blocks/CourseEditorBlockCheckbox.vue";
import CourseEditorBlockConnect from "./blocks/CourseEditorBlockConnect.vue";
import CourseEditorBlockComparison from "./blocks/CourseEditorBlockComparison.vue";
import CourseEditorBlockImage from "./blocks/CourseEditorBlockImage.vue";

// import { GridLayout, GridItem } from "vue-grid-layout"

// let testLayouts = {
//     md: [
//         {"x":0, "y":0, "w":2, "h":2, "i":"0"},
//         {"x":2, "y":0, "w":2, "h":4, "i":"1"},
//         {"x":4, "y":0, "w":2, "h":5, "i":"2"},
//         {"x":6, "y":0, "w":2, "h":3, "i":"3"},
//         {"x":2, "y":4, "w":2, "h":3, "i":"4"},
//         {"x":4, "y":5, "w":2, "h":3, "i":"5"},
//         {"x":0, "y":2, "w":2, "h":5, "i":"6"},
//         {"x":2, "y":7, "w":2, "h":5, "i":"7"},
//         {"x":4, "y":8, "w":2, "h":5, "i":"8"},
//         {"x":6, "y":3, "w":2, "h":4, "i":"9"},
//         {"x":0, "y":7, "w":2, "h":4, "i":"10"},
//         {"x":2, "y":19, "w":2, "h":4, "i":"11"},
//         {"x":0, "y":14, "w":2, "h":5, "i":"12"},
//         {"x":2, "y":14, "w":2, "h":5, "i":"13"},
//         {"x":4, "y":13, "w":2, "h":4, "i":"14"},
//         {"x":6, "y":7, "w":2, "h":4, "i":"15"},
//         {"x":0, "y":19, "w":2, "h":5, "i":"16"},
//         {"x":8, "y":0, "w":2, "h":2, "i":"17"},
//         {"x":0, "y":11, "w":2, "h":3, "i":"18"},
//         {"x":2, "y":12, "w":2, "h":2, "i":"19"}
//     ],
//     lg: [
//         {"x":0,"y":0,"w":2,"h":2,"i":"0"},
//         {"x":2,"y":0,"w":2,"h":4,"i":"1"},
//         {"x":4,"y":0,"w":2,"h":5,"i":"2"},
//         {"x":6,"y":0,"w":2,"h":3,"i":"3"},
//         {"x":8,"y":0,"w":2,"h":3,"i":"4"},
//         {"x":10,"y":0,"w":2,"h":3,"i":"5"},
//         {"x":0,"y":5,"w":2,"h":5,"i":"6"},
//         {"x":2,"y":5,"w":2,"h":5,"i":"7"},
//         {"x":4,"y":5,"w":2,"h":5,"i":"8"},
//         {"x":6,"y":4,"w":2,"h":4,"i":"9"},
//         {"x":8,"y":4,"w":2,"h":4,"i":"10"},
//         {"x":10,"y":4,"w":2,"h":4,"i":"11"},
//         {"x":0,"y":10,"w":2,"h":5,"i":"12"},
//         {"x":2,"y":10,"w":2,"h":5,"i":"13"},
//         {"x":4,"y":8,"w":2,"h":4,"i":"14"},
//         {"x":6,"y":8,"w":2,"h":4,"i":"15"},
//         {"x":8,"y":10,"w":2,"h":5,"i":"16"},
//         {"x":10,"y":4,"w":2,"h":2,"i":"17"},
//         {"x":0,"y":9,"w":2,"h":3,"i":"18"},
//         {"x":2,"y":6,"w":2,"h":2,"i":"19"}
//     ],
// };

export default {
	name: "CourseEditorPageContent",
	components: {
		CourseEditorWidgetEditor,
		CourseEditorBlockParagraph,
		CourseEditorBlockHeader,
		CourseEditorBlockList,
		CourseEditorBlockRadio,
		CourseEditorBlockCheckbox,
		CourseEditorBlockConnect,
		CourseEditorBlockComparison,
		CourseEditorBlockImage,
		// GridLayout,
        // GridItem
	},
	props: {
		chapter: {
			type: Object,
			require: true
		},
		page: {
			type: Object,
			require: true
		}
	},
	computed: {
		activePageId() {
			return this.page?.id;
		},
		activePageBlocks() {
			return this.page?.blocks;
		},
		activePageName() {
			return this.page?.title;
		}
	},
	watch: {
		activePageId: function () {
			if (this.isEditing === true) {
				this.isEditing = false;
			}
		}
	},
	data() {
		return {
			rules: {
				required: value => !!value || "Required."
			},
			isEditing: false,
			pageContent: this.page?.content,
			newPageTitle: this.page.title,
			correctComponent: ""
		};
	},
	methods: {
		// breakpointChangedEvent: function(newBreakpoint, newLayout){
        //     console.log("BREAKPOINT CHANGED breakpoint=", newBreakpoint, ", layout: ", newLayout );
        // },
		setComponent(blk) {
			if (Number(blk.blk_type_id) === 1) {
				return "CourseEditorBlockCheckbox";
			} else if (Number(blk.blk_type_id) === 2) {
				return "CourseEditorBlockConnect";
			} else if (Number(blk.blk_type_id) === 11) {
				return "CourseEditorBlockComparison";
			} else if (Number(blk.blk_type_id) === 4) {
				return "CourseEditorBlockHeader";
			} else if (Number(blk.blk_type_id) === 6) {
				return "CourseEditorBlockImage";
			} else if (Number(blk.blk_type_id) === 7) {
				return "CourseEditorBlockList";
			} else if (Number(blk.blk_type_id) === 8) {
				return "CourseEditorBlockRadio";
			} else if (Number(blk.blk_type_id) === 9) {
				return "CourseEditorBlockParagraph";
			} else {
				return "";
			}
		},
		async cancelEditting() {
			this.setIsEditing(false);
			// this.newPageTitle = "";
		},
		setIsEditing(val) {
			this.isEditing = val;
		},
		async savePageTitle() {
			await put("/page", this.page.id, {
				chapter_id: this.chapter.id,
				title: this.newPageTitle,
				content: this.pageContent,
				sort_order: this.page.sort_order
			});

			await this.$emit("getCourse");
			await this.cancelEditting();
		}
	},
	mounted() {
		console.log(this.page)
	}
};
</script>

<style></style>
