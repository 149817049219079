<template>
	<figure class="course-editor-block-container">
		<div v-for="el in paragraphs" :key="el.id" v-html="doMark(el.content)"></div>
	</figure>
</template>

<script>
import marked from 'marked'
import markdownIt from 'markdown-it';
import { remove } from 'immutable';
import { $ } from 'dom7';
const md = markdownIt().use(require("markdown-it-multimd-table"))
import axios from "axios";

export default {
	name: "CourseEditorBlockParagraph",
	props: {
		paragraphs: {
			type: Array,
			require: true
		}
	},
	methods: {
		doMark(e) {
			let content = md.render(e)
			return content
		}
	},
};
</script>

<style>
table {
	margin: 20px 0;
	width: 100%;
}

.hide {
	display: none;
}

table, td {
    border: 1px solid gray;
}</style>

