<template>
	<figure class="course-editor-block-container">
		<!-- <span class="image-menu"><i class="v-icon notranslate v-icon--link mdi mdi-dots-vertical"></i></span> -->
		<img class="block-image" :src="pathUrl" alt="" />
	</figure>
</template>

<script>
export default {
	name: "CourseEditorBlockImage",
	props: ["file"],
	computed: {
		pathUrl() {
			return this.file?.public_url;
		}
	}
};
</script>

<style lang="scss" scoped>
.course-editor-block-container {
	position: relative;
}
.block-image {
	// max-width: 200px;
	width: 100%;
	position: relative;
}

// .image-menu {
// 	position: absolute;
//     top: 10px;
//     right: 10px;
//     z-index: 1;
//     background-color: #2f91ae;
//     padding: 5px;
// 	color: #fff;
// }
</style>
